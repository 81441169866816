
const config = {
    // fake mode is mode for mock fake line user data can see in class liff.js
    fakeLineMode: false
}
switch (process.env.VUE_APP_MODE) {
    case "production":
        config.fakeLineMode = false
        break;
    case "uat":
        config.fakeLineMode = false
        break;
    case "dev":
        config.fakeLineMode = false
        break;
    case "development":
        config.fakeLineMode = true
        break;
}

export default config