<template>
  <div class="scanQr text-center">
    <img class="my-5" alt="logo" src="@/assets/logo.png" />
    <div>
      <button
        @click="config.fakeLineMode?openQrScanDev():openQrScan()"
        class="btn btn-primary my-4"
        style="max-width:350px;width:80%;"
      >คลิกเพื่อแสกน QR Code ร้านค้า</button>
    </div>
    <div class="my-3 px-3">
      <p>
        <b>หมายเหตุ:</b> กรุณาเตรียมใบเสร็จรับเงินจาก บริษัทเสริมสุข เพื่อใช้สแกนรหัสร้านค้า
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import config from "../../../config";

export default {
  name: "scanQr",
  computed: {
    config() {
      return config;
    },
  },
  methods: {
    // for test
    openQrScanDev() {
      this.checkShop(123);
    },
    openQrScan() {
      // check in app
      if (!window.liff.isInClient()) {
        this.$Error();
      } else {
        window.liff
          .scanCode()
          .then((result) => {
            console.log("qr resualt:", result);
            if (result.value != "null") {
              this.checkShop(result.value.replace("http://", ""));
            }
          })
          .catch((err) => {
            this.$Error();
            console.log(err);
          });
      }
    },
    // check shop ID
    checkShop(shopid) {
      window.loading(true);

      // get shop info
      fetch(process.env.VUE_APP_API_URL + "/shops/" + shopid, {
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      })
        .then((res) => {
          window.loading(false);

          // check status for redirect some page or set params
          if (res.status == 200) {
            this.$router.push("/user/success");
            return;
          } else if (res.status == 403) {
            this.$router.push({
              name: "auth",
              params: { shopid: shopid, existMember: true },
            });
            return;
          }
          if (res.status == 204) {
            this.$router.push({
              name: "auth",
              params: { shopid: shopid, existMember: false },
            });
            return;
          }

          // if in case give error
          console.log("status no case", res);
          this.$Error();
        })
        .catch((error) => {
          console.log("fetch error:", error);
          this.$Error();
        });
    },
  },
  async mounted() {},
};
</script>
